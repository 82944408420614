<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <div>
    <v-scroll-x-transition>
      <router-view
        v-show="isRoute(APP_ROUTES.order.show)"
        name="show"
      />
    </v-scroll-x-transition>
    <v-scroll-x-reverse-transition
      hide-on-leave
      leave-absolute
    >
      <app-card
        v-show="isRoute(APP_ROUTES.order.index)"
        :title="cardTitle"
      >
        <app-datatable
          ref="datatable"
          :btn-dialog="userHasPermission('store')"
          :form-loading.sync="formLoading"
          :form.sync="dialog"
          :headers="headers"
          :loading-datatable.sync="loadingDatatable"
          :modal-title="datatableDialogTitle"
          :show-select="userHasPermission('assignOrders')"
          :url="tableUrl"
          @submitClickForm="submitClickForm"
        >
          <template #top="{selectedItems}">
            <v-container>
              <v-row
                align="center"
                justify="space-between"
              >
                <v-col cols="auto">
                  <app-btn
                    color="success"
                    @click="onFollowUp()"
                  >
                    <template v-if="!isFollowUp">
                      {{ parseAttribute('follow_up_orders') }}
                    </template>
                    <template v-else>
                      {{ $t('show_all') }}
                    </template>
                  </app-btn>
                </v-col>
                <app-switcher
                  v-model="hideNotes"
                  cols="auto"
                  name="hide_notes"
                />
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-divider />
                </v-col>
              </v-row>
              <v-row
                v-show="!isFollowUp"
                dense
              >
                <app-order-categories
                  class="d-none"
                  name="order_category_id"
                  @set-items="orderCategories = $event"
                />
                <v-col
                  v-if="orderCategories.length>0"
                  cols="auto"
                >
                  <app-btn
                    :disabled="!selectedOrderCat || loadingDatatable"
                    class="success"
                    @click="onOrderCat()"
                  >
                    {{ $t('show_all') }}
                  </app-btn>
                </v-col>
                <v-col
                  v-for="(c,i) in orderCategories"
                  :key="`cat${i}`"
                  cols="auto"
                >
                  <app-btn
                    :disabled="selectedOrderCat === c.id || loadingDatatable"
                    @click="onOrderCat(c)"
                  >
                    {{ c.text }}
                  </app-btn>
                </v-col>
              </v-row>
              <v-row v-show="selectedItems?.length > 0 && userHasPermission('assignOrders')">
                <v-col cols="12">
                  <v-divider />
                </v-col>
                <v-col cols="12">
                  <v-card>
                    <v-card-title>
                      {{ $t('hints.assign_order_title') }}
                    </v-card-title>
                    <v-card-text>
                      <v-row align="center">
                        <v-col cols="auto">
                          <v-card>
                            <div class="text-body-1 pa-3">
                              {{ $tc('choice.Orders', 2) }}: {{ selectedItems?.length }}
                            </div>
                          </v-card>
                        </v-col>
                        <app-static-users
                          v-model="assignedUser"
                          cols="12"
                          md="6"
                          name="user_id"
                        />
                        <app-btn
                          :disabled="!assignedUser"
                          :loading="assignLoading"
                          color="success"
                          @click="onAssignOrders(selectedItems)"
                        >
                          <v-icon left>
                            mdi-clipboard-check
                          </v-icon>
                          {{ $t('update') }}
                        </app-btn>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-divider />
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template #filter="{datatableFilters}">
            <v-container>
              <v-row>
                <app-users
                  v-if="isAdminUser"
                  v-model="datatableFilters.user_id"
                  clearable
                  cols="12"
                  multiple
                  name="user_id"
                  sm="6"
                />
                <app-filter-select
                  v-model="datatableFilters.no_user"
                  cols="12"
                  name="no_user"
                  sm="6"
                />
              </v-row>
              <v-row>
                <app-date-picker
                  v-model="datatableFilters.from_date"
                  cols="12"
                  name="from_date"
                  sm="6"
                />
                <app-date-picker
                  v-model="datatableFilters.to_date"
                  cols="12"
                  name="to_date"
                  sm="6"
                />
              </v-row>
              <!--<v-row>-->
              <!--<app-order-statuses-->
              <!--  v-model="datatableFilters.order_status_id"-->
              <!--  cols="12"-->
              <!--  name="order_status_id"-->
              <!--  clearable-->
              <!--  sm="6"-->
              <!--/>-->
              <!--<app-order-categories-->
              <!--  v-model="datatableFilters.order_category_id"-->
              <!--  cols="12"-->
              <!--  name="order_category_id"-->
              <!--  clearable-->
              <!--  sm="6"-->
              <!--/>-->
              <!--</v-row>-->
              <v-row>
                <app-number-input
                  v-model="datatableFilters.mobile"
                  clearable
                  cols="12"
                  name="mobile"
                  sm="6"
                />
                <app-job-positions
                  v-model="datatableFilters.job_position_id"
                  clearable
                  cols="12"
                  multiple
                  name="job_position_id"
                  sm="6"
                />
                <app-banks
                  v-model="datatableFilters.bank_id"
                  clearable
                  cols="12"
                  multiple
                  name="bank_id"
                  sm="6"
                />
                <app-service-types
                  v-model="datatableFilters.service_type_id"
                  clearable
                  cols="12"
                  multiple
                  name="service_type_id"
                  sm="6"
                />
                <app-filter-select
                  v-model="datatableFilters.have_obligations"
                  cols="12"
                  name="have_obligations"
                  sm="6"
                />
              </v-row>
            </v-container>
          </template>
          <template #form="props">
            <app-form
              ref="form"
              :errors="formErrors"
              :submit="submitForm"
            >
              <v-container>
                <order-form :form.sync="selectedItem" />
              </v-container>
            </app-form>
          </template>

          <template #item.control="{item}">
            <app-dt-btn
              v-if="userHasPermission('update')"
              :disabled="loadingDatatable"
              update
              @click="showUpdateDialog(item)"
            />
            <app-dt-btn
              v-if="userHasPermission('destroy')"
              :disabled="loadingDatatable"
              destroy
              @click="deleteItem(item)"
            />
            <app-dt-btn
              v-if="userHasPermission('show')"
              :disabled="loadingDatatable"
              show
              @click="showItem(item)"
            />
          </template>
        </app-datatable>
      </app-card>
    </v-scroll-x-reverse-transition>
  </div>
</template>
<script>
import { DatatableMixin, MetaInfoMixin } from '@mixins'
import OrderForm from '@components/order/OrderForm'

export default {
  name: 'Index',
  components: { OrderForm },
  helperApiName: 'order',
  mixins: [MetaInfoMixin, DatatableMixin],
  data () {
    return {
      modelPermission: 'Sales.Order',
      orderCategories: [],
      selectedOrderCat: null,
      assignLoading: !1,
      assignedUser: null,
      hideNotes: !1
    }
  },
  computed: {
    isFollowUp: {
      get () {
        return this.$route?.query?.follow_up?.toString() === '1' ? !0 : !1
      },
      set (v) {
        const n = Boolean(v)
        if (n === this.isFollowUp) {
          return
        }
        const query = { ...this.$route?.query, follow_up: v ? '1' : '0' }
        delete query.id
        this.$router.push({ name: this.APP_ROUTES.order.index, query })
      }
    },
    headers () {
      return [
        'id',
        // 'order_status_id_to_string',
        // 'order_category_id_to_string',
        (this.isAdminUser ? 'user_id_to_string' : null),
        'name',
        'mobile',
        'job_position_id_to_string',
        'salary_to_string',
        'bank_id_to_string',
        'age_to_string',
        'service_type_id_to_string',
        'have_obligations_to_string',
        'date_to_string',
        (!this.hideNotes ? 'order_notes' : null),
        'control'
      ]
    },
    defaultSelectedItem () {
      return {
        order_amount: 0,
        discount: 0,
        fixed_app_fees: 0,
        order_total: 0,
        vat: 0
      }
    },
    cardTitle () {
      if (this.isFollowUp) {
        return this.parseAttribute('follow_up_orders')
      }
      if (this.selectedOrderCat) {
        const title = this.getPageTitle(2)
        const name = this.orderCategories.find(e => e.id === this.selectedOrderCat)?.name || undefined
        return title + (name ? ` - ${name}` : '')
      }
      return undefined
    }
  },
  watch: {
    '$route.query.id': {
      handler: 'checkOrderId',
      deep: !0,
      immediate: !0
    }
  },
  mounted () {
    this.checkPermission('index')
    const id = this.$route.query.order_category_id
    if (id) {
      setTimeout(() => {
        this.onOrderCat({ id: parseInt(id) })
      }, 900)
    }
  },
  methods: {
    onFollowUp () {
      this.$refs.datatable?.clearRouteFilters()
      this.onOrderCat(null)
      this.$nextTick(() => {
        this.isFollowUp = !this.isFollowUp
      })
    },
    onAssignOrders (orders) {
      if (!this.assignedUser || this.assignLoading) {
        return
      }
      this.assignLoading = !0
      this.apiService.order.assignOrders({
        order_id: orders.map(e => e.id),
        user_id: this.assignedUser
      })
        .then(({ _message }) => {
          _message && this.alertSuccess(_message)
          this.assignedUser = null
          this.$nextTick(() => {
            this.$refs.datatable.refresh()
          })
        })
        .catch(({ _message }) => {
          _message && this.alertError(_message)
        })
        .finally(() => {
          this.assignLoading = !1
        })
    },
    showItem (item) {
      if (!this.isRoute(this.APP_ROUTES.order.show)) {
        this.$router.push({ name: this.APP_ROUTES.order.show, params: { id: item.id }, props: !0 })
      }
    },
    onOrderCat (cat) {
      const id = cat?.id || null
      this.selectedOrderCat = id
      this.$nextTick(() => {
        if (!this.$refs.datatable) {
          return
        }
        this.$refs.datatable.setDatatableFilters({ order_category_id: id })
      })
    },
    checkOrderId (v) {
      if (!v) {
        return
      }
      setTimeout(() => {
        this.$nextTick(() => {
          const id = v
          if (id && this.$refs.datatable.setDatatableOptions) {
            // this.$refs.datatable.setDatatableOptions({ search: `${id}` })
            this.$refs.datatable.setDatatableFilters({ id: parseInt(id) })
            // this.$refs.datatable.setSearch(id)
            // this.$refs.datatable.refresh()
            // console.log(parseInt(id))
          }
          // console.log()
        })
      }, 60)
    }
  }
}
</script>
