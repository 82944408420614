<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-row no-gutters>
    <app-static-users
      class="d-none"
      name="usersSelect"
      @set-items="usersSelect = $event"
    />
    <v-col cols="12">
      <slot name="before" />
      <order-inputs :form.sync="computedForm">
        <template #before>
          <v-row>
            <app-auto-select
              v-if="isAdminUser"
              v-model="computedForm.user_id"
              :items="usersSelect"
              clearable
              cols="12"
              name="user_id"
              sm="6"
            />
            <!--<app-order-statuses-->
            <!--  v-model="computedForm.order_status_id"-->
            <!--  cols="12"-->
            <!--  name="order_status_id"-->
            <!--  required-->
            <!--  sm="6"-->
            <!--/>-->
            <app-order-categories
              v-model="computedForm.order_category_id"
              clearable
              cols="12"
              name="order_category_id"
              sm="6"
            />
          </v-row>
        </template>

        <v-row>
          <app-textarea-input
            v-if="!hideNotes"
            v-model="computedForm.order_notes"
            clearable
            cols="12"
            name="order_notes"
            sm="12"
          />
        </v-row>

        <v-row>
          <v-col cols="12">
            <div class="text-h6">
              {{ parseAttribute('owner_information') }}
            </div>
          </v-col>
          <app-text-input
            v-model="computedForm.owner_name"
            clearable
            cols="12"
            name="owner_name"
            sm="6"
          />
          <app-mobile-input
            v-model="computedForm.owner_mobile"
            clearable
            cols="12"
            name="owner_mobile"
            persistent-placeholder
            rules="mobile"
            sm="6"
          />
          <app-float-input
            v-model="computedForm.down_payment"
            :suffix="parseAttribute('sar')"
            clearable
            cols="12"
            name="down_payment"
            sm="6"
          />
          <app-float-input
            v-model="computedForm.amount_before_variance"
            :suffix="parseAttribute('sar')"
            clearable
            cols="12"
            name="amount_before_variance"
            sm="6"
          />
          <app-float-input
            v-model="computedForm.amount_after_variance"
            :suffix="parseAttribute('sar')"
            clearable
            cols="12"
            name="amount_after_variance"
            sm="6"
          />
          <app-float-input
            v-model="computedForm.finance_amount"
            :suffix="parseAttribute('sar')"
            clearable
            cols="12"
            name="finance_amount"
            sm="6"
          />
          <app-float-input
            v-model="computedForm.owner_check"
            :suffix="parseAttribute('sar')"
            clearable
            cols="12"
            name="owner_check"
            sm="6"
          />
          <app-float-input
            v-model="computedForm.fees_amount"
            :suffix="parseAttribute('sar')"
            clearable
            cols="12"
            name="fees_amount"
            sm="6"
          />
          <app-float-input
            v-model="computedForm.finance_calc_amount"
            :suffix="parseAttribute('sar')"
            clearable
            cols="12"
            name="finance_calc_amount"
            sm="6"
          />
          <app-float-input
            v-model="computedForm.real_estate_installment"
            :suffix="parseAttribute('sar')"
            clearable
            cols="12"
            name="real_estate_installment"
            sm="6"
          />
          <app-banks
            v-model="computedForm.finance_bank_id"
            clearable
            cols="12"
            name="finance_bank_id"
            sm="6"
          />
        </v-row>
      </order-inputs>
      <slot />
    </v-col>
  </v-row>
</template>

<script>

import OrderInputs from '@components/order/OrderInputs'

export default {
  name: 'OrderForm',
  components: { OrderInputs },
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    hideNotes: {
      type: Boolean,
      default: () => !1
    }
  },
  data () {
    return {
      usersSelect: []
    }
  },
  computed: {
    computedForm: {
      get () {
        return this.form
      },
      set (v) {
        this.$emit('update:form', v)
      }
    }
  },
  methods: {}
}
</script>
